<template>
    <v-menu v-if="notifications.length >= 1" open-on-click :close-on-content-click="false" offset-y transition="slide-x-transition" bottom right>
        <template v-slot:activator="{ on, attrs }">
            <v-btn fab v-bind="attrs" v-on="on" elevation="1" small color="green"  class="mr-3 mr-sm-3 mr-md-4" style="position: relative; ">
                <CustomIcon :size="25" color="white" glyph="mdi-bell-outline" /> 
                <p class="subscript_overlay" style="">
                    {{ notifications.length }}
                </p>
            </v-btn>
        </template>
        <v-list dense style="overflow-y: auto; max-height:400px;">
            <v-divider></v-divider>
            <div v-for="(n, index) in notifications" :key="index">
                <v-list-item>
                    <v-list-item-action color="dark green">
                        <v-list-item-title>
                            <a :href="n.nav_url" @click="deleteNotification(n.notification_id)" color="black" style="color:black">
                                <v-list-item-title color="black">{{ n.notification_message }}</v-list-item-title>
                            </a>
                        </v-list-item-title>
                    </v-list-item-action>
                    <CustomIcon @click="deleteNotification(n.notification_id)" size="40" color="dark green" glyph="mdi-alpha-x" /> 
                </v-list-item>
                <v-divider></v-divider>
            </div>
        </v-list>
        <v-divider></v-divider>
        <v-list>
            <v-list-item @click="deleteNotification()" v-if="notifications.length >= 5" >
                <p style="width:100%; text-align:center;">
                    CLEAR ALL
                </p>
            </v-list-item>
        </v-list>
    </v-menu>

</template>
<script>
import api from '/src/utils/api';

export default {
    components: {
        CustomIcon: () => import("/src/components/CustomIcon.vue"),
    },
    data() {
        return {
            notifications: [],
        };
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            api.getCall('get-notifications').then(notifications => {
                this.notifications = notifications;
            });
        },
        deleteNotification(notificationId) {
            let id = notificationId || null;
            api.postAuthRequired('delete-notification',{"notificationId": id}).then((res) => {
                this.refresh();
            });
        },
    },
};
</script>
<style scoped>
a {
  text-decoration: none;
}
.subscript_overlay {
    color:red; 
    background-color: white; 
    display: flex; 
    align-items: center;
    justify-content: center;
    position:absolute; 
    top:-10px; 
    right:-10px; 
    height:20px; 
    width:20px; 
    border-radius: 10px;
}
</style>